<template>
  <modal
    name="modal-configuracao-usuario"
    scrollable
    :width="500"
    height="auto"
  >
    <!-- close icon -->

    <button
      type="button"
      class="close mt-3 mr-3"
      @click="$modal.hide('modal-configuracao-usuario')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-config-container">
      <h3 class="text-center">Configurações</h3>
      <div class="config-options-container mt-5">
        <div class="config-option">
          <div class="config-option-input">
            <InputSwitch
              id="stages_by_category"
              v-model="usuario_config.stages_by_category"
            />
          </div>
          <div class="config-option-label">
            <label for="stages_by_category"
              >Separar estágios por categoria</label
            >
          </div>
        </div>
        <!-- default_initial_zoom -->
        <div class="config-option">
          <div class="config-option-input">
            <!-- zoom_options -->
            <b-form-select
              id="default_initial_zoom"
              v-model="usuario_config.default_initial_zoom"
              :options="zoom_options"
            />
          </div>
          <div class="config-option-label">
            <label for="default_initial_zoom">Zoom inicial padrão</label>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";
import InputSwitch from "primevue/inputswitch";

export default {
  components: {
    InputSwitch
  },
  props: {
    usuario_config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      zoom_options: [
        { text: "150%", value: 1.5 },
        { text: "140%", value: 1.4 },
        { text: "130%", value: 1.3 },
        { text: "120%", value: 1.2 },
        { text: "110%", value: 1.1 },
        { text: "100%", value: 1 },
        { text: "90%", value: 0.9 },
        { text: "80%", value: 0.8 },
        { text: "70%", value: 0.7 },
        { text: "60%", value: 0.6 },
        { text: "50%", value: 0.5 }
      ]
    };
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.modal-config-container {
  padding: 24px;
  .config-options-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .config-option {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
</style>
