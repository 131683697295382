<template>
  <modal name="modal-ajuda-chatbot" scrollable :width="modalSize" height="auto">
    <div class="modal-ajuda-chatbot">
      <!-- <div  class="botao-fechar">
        <button @click="$modal.hide('modal-ajuda-chatbot')">❌</button>
      </div> -->

      <h2 class="title text-center">Como Funciona o Chatbot?</h2>
      <br />
      <p>
        O chatbot é um assistente virtual que pode ser utilizado para
        automatizar o atendimento de sua empresa. Ele pode ser utilizado para
        responder perguntas frequentes, enviar conteúdos, entre outras
        funcionalidades.
      </p>
      <br />
      <p>
        Para criar um fluxo de Chatbot, você deve seguir os seguintes passos:
      </p>
      <br />
      <p>
        1. Adicione um estágio inicial, "Enviar Mensagem" por exemplo. <br />
        2. Adicione um estágio de "Aguardar Resposta" para salvar a resposta do
        usuário. <br />
        3. Adicione um estágio de "Tomar Decisão" para verificar e tomar
        decisões com base na resposta do usuário. <br />
        4. Adicione estágios de "Enviar Mensagem" para enviar mensagens de
        acordo com a resposta do usuário. <br />
        5. Adicione um estágio final, "Fim de Atendimento" por exemplo. <br />
        6. Fim 😊
      </p>
      <div>
        <h3>Atalhos Padrões</h3>
        <ul style="padding-left: 16px !important">
          <li>ctrl+alt+s - Salvar fluxo</li>
          <li>ctrl+alt+f - Tela cheia</li>
          <li>ctrl+shift+c - Copiar fluxo</li>
          <li>ctrl+z - Desfazer alteração</li>
          <li>ctrl+y - Refazer alteração</li>
          <li>ctrl+d - Duplicar estágio</li>
          <li>ctrl+shift+z - Refazer alteração</li>
          <li>ctrl+shift+seta pra cima - Navegar no fluxo para cima</li>
          <li>ctrl+shift+seta pra baixo - Navegar no fluxo para baixo</li>
          <li>ctrl+shift+seta pra esquerda - Navegar no fluxo para esquerda</li>
          <li>ctrl+shift+seta pra direita - Navegar no fluxo para direita</li>
        </ul>
      </div>
      <div>
        <h3>Atalhos Avançados</h3>
        <ul style="padding-left: 16px !important">
          <li>ctrl+shift+alt+n - copiar nodes</li>
          <li>ctrl+shift+alt+l - copiar links</li>
          <li>ctrl+alt+n - Enviar erro para Nathan</li>
        </ul>
      </div>
    </div>
  </modal>
</template>

<script>
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      menu: {}
    };
  },
  methods: {},
  mounted() {
    // this.pegarMenusChatbot();
  },
  computed: {
    modalSize() {
      if (window.screen.width > 900) return "900";
      return "400";
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-ajuda-chatbot {
  padding: 24px !important;
  .flexer-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    /* align-items: center; */
    min-height: 100% !important;
  }

  .flexer-col {
    /* flex: 4; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100% !important;

    /* only first child
       */
    > .form-group:first-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
