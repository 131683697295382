<template>
  <div class="chatbot-container">
    <Toast position="top-right" />
    <Toast position="bottom-right" group="br" />
    <ModalConfiguracaoUsuario :usuario_config="usuario_config" />
    <ModalEstagio />
    <ModalConfirmacaoSair :aba="2" direct_page="chatbot" />
    <ModalPresets @confirmarPreset="substituirFluxoAtualPeloPreset" />
    <ModalAjudaChatbot />
    <ModalCategoriasChatbot
      :categories="categories_chatbot_full_object"
      @refreshCategories="getCategories"
    />

    <div class="camp-header">
      <div class="back-arrow-squared" @click="showModalConfirmacaoSair">
        <i class="flaticon-up-arrow" style="color: #808080 !important"></i>
      </div>
      <b-button class="btn-success" variant="primary" @click="salvarFluxo"
        >Salvar Fluxo</b-button
      >
    </div>
    <div class="chatbot-header">
      <div class="top-header w-100">
        <div>
          <p class="text-title">Título do Fluxo</p>
          <b-form-input
            type="text"
            v-model="tituloFluxo"
            style="width: 250px"
          ></b-form-input>
        </div>
        <div class="w-100">
          <p class="text-title">Descrição do Fluxo</p>
          <b-form-input
            type="text"
            v-model="descricaoFluxo"
            style="width: 100%"
          ></b-form-input>
        </div>
        <div>
          <p class="text-title">Categorias do Fluxo</p>
          <MultiSelect
            style="width: 250px; height: 38.5px"
            v-model="selected_categories"
            :options="categories_chatbot"
            optionLabel="name"
            :filter="true"
            size="lg"
          />
        </div>
        <div class="d-flex align-items-center" style="gap: 0.5rem">
          <b-button
            class="orange-btn"
            variant="primary"
            @click="openChatbotCategoryModal"
            style="white-space: nowrap"
            >Cadastrar Categoria</b-button
          >
          <b-button
            class="orange-btn"
            variant="primary"
            @click="abrirModalAjudaFluxo"
          >
            <i class="fas fa-question-circle p-0"></i>
          </b-button>
        </div>
      </div>
      <div
        class="botoes-iteracao-container"
        :style="{
          'align-items': usuario_config.stages_by_category
            ? 'flex-end'
            : 'center'
        }"
      >
        <div class="esquerda">
          <div v-if="usuario_config.stages_by_category">
            <p class="text-title">Categorias de Estagio</p>
            <b-form-select
              v-model="chatbot_estagio_categoria_selecionada"
              :options="categorias"
              style="width: 250px"
              class="mr-2"
            ></b-form-select>
          </div>
          <div v-else>
            <b-form-select
              v-model="chatbotEstagioSelecionado"
              :options="chatbotEstagioOpcoes"
              style="width: 200px"
              class="mr-2"
            ></b-form-select>
            <b-button
              variant="primary"
              @click="addNode(null)"
              class="orange-btn mr-2"
              >Novo Estágio</b-button
            >
          </div>
        </div>
        <div class="direita">
          <b-button
            variant="primary"
            @click="testarFluxo"
            v-if="modoEdicao"
            class="orange-btn mr-2"
          >
            Testar
          </b-button>
          <b-button
            variant="primary"
            @click="copiarFluxo(false)"
            class="orange-btn mr-2"
          >
            Copiar
          </b-button>
          <b-button
            variant="primary"
            @click="colarFluxo"
            class="orange-btn mr-2"
          >
            Colar
          </b-button>
          <b-button
            variant="primary"
            @click="mostrarModalPresets"
            class="orange-btn mr-2"
          >
            Templates
          </b-button>
          <b-button
            class="mr-2 orange-btn news"
            variant="primary"
            @click="formatFlowchart"
            ref="FormatFlowchartBtn"
            >Formatar</b-button
          >
          <b-button
            class="mr-2 orange-btn"
            variant="primary"
            ref="CenterFlowchartBtn"
            @click="centerFlowchart"
            ><i class="fa-solid fa-bullseye pr-0"></i
          ></b-button>
          <b-button class="mr-2 orange-btn" variant="primary" @click="zoomIn"
            >+</b-button
          >
          <b-button class="mr-2 orange-btn" variant="primary" @click="zoomOut"
            >-</b-button
          >
          <b-button
            variant="primary"
            @click="toggleFullscreen"
            class="orange-btn mr-2"
          >
            <template v-if="!isFullScreen">
              <i class="fa-solid fa-maximize"></i> Tela Cheia
            </template>
            <template v-else>
              <i class="fa-solid fa-minimize"></i> Tela Normal
            </template>
          </b-button>
          <!-- button of cog -->
          <b-button
            variant="primary"
            @click="showModalConfiguracoes"
            class="orange-btn"
          >
            <i class="fa-solid fa-cog p-0"></i>
          </b-button>
        </div>
      </div>
      <div
        class="botoes-iteracao-container"
        v-if="usuario_config.stages_by_category"
      >
        <div class="esquerda">
          <b-button
            v-for="estagio in chatbotEstagios.filter(
              (estagio) =>
                estagio.ces_categoria == chatbot_estagio_categoria_selecionada
            )"
            :key="estagio.chatbot_estagio_id"
            variant="primary"
            @click="addNode(estagio.chatbot_estagio_id)"
            class="stage-btn mr-2"
            style="text-transform: none !important"
            :class="{
              'decision-type': [10, 11].includes(estagio.chatbot_estagio_id),
              'finish-type': [4].includes(estagio.chatbot_estagio_id)
            }"
          >
            {{ estagio.ces_nome }}
          </b-button>
        </div>
      </div>
    </div>
    <div class="fluxo-container">
      <div
        style="overflow: hidden; border-radius: 0 0 10px 10px; height: 600px"
        :style="isFullScreen ? 'height: 100vh' : 'height: 600px'"
      >
        <simple-flowchart
          @nodeClick="nodeClick"
          @nodeDelete="nodeDelete"
          @linkBreak="linkBreak"
          @linkAdded="linkAdded"
          @canvasClick="canvasClick"
          :scene.sync="flowchartDataProperty"
        ></simple-flowchart>
      </div>
    </div>
    <SidebarFluxo
      :isVisible="sidebarVisible"
      :activeNode="activeNode"
      :sistemas="sistemas"
      :childrens="childrens"
      :modelos="chatbotModelos"
      :times="times"
      :atributos_customizaveis="custom_attributes"
      :agendas="agendas"
      :opcoesFormatacaoEntrada="opcoesFormatacaoEntrada"
      :estagiosPai="estagiosPai"
      :variaveisDeclaradas="variaveisDeclaradas"
      :modeloLinks="modeloLinks"
      :chatbotCallbacks="chatbotCallbacks"
      @abrirModalInformacoesEstagio="abrirModalInformacoesEstagio"
      :fluxosDeChatbot="fluxosDeChatbot"
      :prioridades="prioridades"
      :tipo_reagendamento="tipo_reagendamento"
      :tipo_req_http="tipo_req_http"
    />
  </div>
</template>

<script>
import SimpleFlowchart from "vue-simple-flowchart";
import "vue-simple-flowchart/dist/vue-flowchart.css";
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";
import ModalEstagio from "./ModalEstagio.vue";
import SidebarFluxo from "./SidebarFluxo.vue";
import Toast from "primevue/toast";
import ModalConfirmacaoSair from "../../campanha/cadastro-componentes/ModalConfirmacaoSair.vue";
import ModalAjudaChatbot from "./ModalAjudaChatbot.vue";
import ModalPresets from "./ModalPresets.vue";
import ModalCategoriasChatbot from "./ModalCategoriasChatbot.vue";
import Swal from "sweetalert2";
import utils from "@teteu/utils";
import ModalConfiguracaoUsuario from "./ModalConfiguracaoUsuario.vue";
import MultiSelect from "primevue/multiselect";

import { mapGetters } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import config from "@/core/config/layout.config.json";

export default {
  components: {
    MultiSelect,
    SimpleFlowchart,
    ModalEstagio,
    SidebarFluxo,
    Toast,
    ModalConfirmacaoSair,
    ModalAjudaChatbot,
    ModalPresets,
    ModalConfiguracaoUsuario,
    ModalCategoriasChatbot
  },
  data() {
    return {
      selected_categories: [],
      categories_chatbot: [],
      categories_chatbot_full_object: [],
      custom_attributes: [],
      fluxo_teste_id: null,
      usuario_config: {
        stages_by_category: true,
        default_initial_zoom: 1
      },
      chatbot_estagio_categoria_selecionada: null,
      categorias: [],
      recursosDoUsuario: [],
      chatbotCallbacks: [],
      agendas: [],
      variaveisObj: {
        declaradas: [],
        utilizadas: []
      },
      modeloLinks: [],
      times: [{ label: "1", value: 1 }],
      verificacoesAtivas: true,
      ultimoEstagioDaConexaoViaLink: {},
      lastNodeToLineIteration: {},
      lastConnections: [],
      modoEdicao: false,
      opcoesFormatacaoEntrada: [],
      isFullScreen: false,
      tituloFluxo: "",
      descricaoFluxo: "",
      currentZoom: 1,
      initial_id: 1,
      chatbotEstagios: [],
      chatbotCampanhas: [],
      chatbotSistemas: [],
      chatbotModelos: [],
      chatbotEstagioOpcoes: [],
      chatbotEstagioSelecionado: {},
      lastXCordinate: -800,
      lastYCordinate: -100,
      flowchartDataProperty: {
        centerX: 1024,
        centerY: 140,
        scale: 1,
        nodes: [],
        links: []
      },
      activeNode: {},
      sidebarVisible: false,
      sistemas: [],
      prioridades: [
        { value: null, text: "" },
        { value: "low", text: "Baixa" },
        { value: "medium", text: "Normal" },
        { value: "high", text: "Alta" },
        { value: "urgent", text: "Urgente" }
      ],
      tipo_reagendamento: [
        { value: null, text: "" },
        { value: "1", text: "Passar para Atendente" },
        { value: "2", text: "Agendamento Automático" }
      ],
      tipo_req_http: [
        { value: null, text: "Selecione o Método HTTP" },
        { value: "GET", text: "GET" },
        { value: "DELETE", text: "DELETE" },
        { value: "HEAD", text: "HEAD" },
        { value: "OPTIONS", text: "OPTIONS" },
        { value: "POST", text: "POST" },
        { value: "PUT", text: "PUT" },
        { value: "PATCH", text: "PATCH" },
        { value: "PURGE", text: "PURGE" },
        { value: "LINK", text: "LINK" },
        { value: "UNLINK", text: "UNLINK" }
      ],
      flag_stack: true,
      qtdNodes: 0,
      qtdLinks: 0,
      stackFlowchartDataProperty: [],
      redoStackFlowchartDataProperty: [],
      fluxosDeChatbot: [],
      current_centerX: 1024,
      current_centerY: 140
    };
  },
  watch: {
    usuario_config: {
      handler: function (val, oldVal) {
        localStorage.setItem("usuario_config", JSON.stringify(val));
      },
      deep: true,
      immediate: false
    },
    flowchartDataProperty: {
      handler: function (val, oldVal) {
        if (this.flag_stack) {
          if (
            val.nodes.length !== this.qtdNodes ||
            val.links.length !== this.qtdLinks
          ) {
            this.qtdNodes = val.nodes.length;
            this.qtdLinks = val.links.length;
            const clone = JSON.parse(JSON.stringify(val));
            this.stackFlowchartDataProperty.push(clone);
          }
        }
      },
      deep: true
    },
    "flowchartDataProperty.nodes": {
      handler: function (newVal, oldVal) {
        if (newVal.length > oldVal.length) {
          newVal.forEach((node, index) => {
            node.ces_nome = index + "-" + node.ces_nome;
          });
        }
      },
      deep: false
    },
    "flowchartDataProperty.links": {
      handler: function (newVal, oldVal) {
        const node = this.flowchartDataProperty.nodes.find((node) => {
          if (newVal.length > 0) {
            return node.id === newVal[newVal.length - 1].from;
          }
        });
        if (!node) return;

        if (this.ultimoEstagioDaConexaoViaLink.chatbot_estagio_id === 10) {
          const tomarDecisao = [
            {
              label: "Estágio que a condição leva",
              value: null,
              bindText: "para_chatbot_fluxo_estagio_id",
              type: "select",
              required: true
            },
            {
              label: "Condição que leva ao estágio",
              value: null,
              bindText: "cec_condicao",
              type: "text",
              required: true
            },
            {
              label: "Ordem de verificação dessa condição",
              value: null,
              bindText: "cec_ordem",
              type: "text",
              required: true
            },
            {
              label: "Parâmetro",
              value: null,
              bindText: "cec_parametro",
              type: "select",
              required: true
            },
            {
              label: "Operador",
              value: null,
              bindText: "cec_operador",
              type: "select",
              required: true
            },
            {
              label: "Valor",
              value: null,
              bindText: "cec_valor",
              type: "text",
              required: true
            },
            {
              label: "Avancado",
              onLabel: "Modo Padrão",
              offLabel: "Modo Avançado",
              value: false,
              bindText: "cec_avancado",
              type: "bool",
              required: true
            }
          ];

          if (newVal.length > this.lastConnections.length) {
            let added_connection = null;
            newVal.forEach((link) => {
              if (
                !this.lastConnections.find(
                  (oldLink) =>
                    oldLink.from === link.from && oldLink.to === link.to
                )
              ) {
                added_connection = link;
              }
            });
            const current_childs = [
              ...this.ultimoEstagioDaConexaoViaLink.ces_metadata.campos
            ];
            const index = current_childs.findIndex((child) => {
              return child[0].value > added_connection?.to;
            });
            if (index == -1) {
              this.ultimoEstagioDaConexaoViaLink.ces_metadata.campos.push([
                ...tomarDecisao
              ]);
            } else {
              this.ultimoEstagioDaConexaoViaLink.ces_metadata.campos.splice(
                index,
                0,
                [...tomarDecisao]
              );
            }
          } else if (newVal.length < this.lastConnections.length) {
            let removed_connection = null;
            oldVal.forEach((link) => {
              if (
                !newVal.find(
                  (newLink) =>
                    newLink.from === link.from && newLink.to === link.to
                )
              ) {
                removed_connection = link;
              }
            });
            this.ultimoEstagioDaConexaoViaLink.ces_metadata.campos =
              this.ultimoEstagioDaConexaoViaLink.ces_metadata.campos.filter(
                (campo) => {
                  if (Array.isArray(campo)) {
                    return campo[0].value !== removed_connection?.to;
                  } else {
                    return campo.value !== removed_connection?.to;
                  }
                }
              );
          }

          const qtdFilhos = newVal.filter(
            (link) => link.from === node.id
          ).length;

          if (qtdFilhos > node.ces_metadata.qtd_max_filho) {
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: `Quantidade máxima de filhos excedida.`,
              life: 7000
            });
            newVal.pop();
          }
        }
        this.lastConnections = [...newVal];
        let lastConnection = {};
        if (newVal.length > 0) {
          lastConnection = newVal[newVal.length - 1];
        }
        if (
          newVal.find(
            (link) =>
              link.to === lastConnection.from && link.from === lastConnection.to
          )
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: `Não é possível conectar um node ao node pai.`,
            life: 7000
          });
          newVal.pop();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["isAdmin", "hasPermission"]),
    variaveisUtilizadas() {
      const variaveis = [];
      this.flowchartDataProperty.nodes.forEach((node) => {
        if ([1, 10, 11].includes(node.chatbot_estagio_id)) {
          if (Array.isArray(node.ces_metadata.campos[0])) {
            node.ces_metadata.campos.forEach((child) => {
              child.forEach((campo) => {
                if (
                  (campo.type == "text" || campo.type == "textArea") &&
                  isNaN(campo.value)
                ) {
                  const regex = /{([^}]+)}/g;
                  const matches = campo.value.match(regex);
                  if (matches) {
                    matches.forEach((match) => {
                      const variavel = match.replace("{", "").replace("}", "");
                      if (!variaveis.includes(variavel)) {
                        variaveis.push(variavel);
                      }
                    });
                  }
                }
              });
            });
          } else {
            node.ces_metadata.campos.forEach((campo) => {
              if (
                (campo.type == "text" || campo.type == "textArea") &&
                isNaN(campo.value)
              ) {
                const regex = /{([^}]+)}/g;
                const matches = campo.value.match(regex);
                if (matches) {
                  matches.forEach((match) => {
                    const variavel = match.replace("{", "").replace("}", "");
                    if (!variaveis.includes(variavel)) {
                      variaveis.push(variavel);
                    }
                  });
                }
              }
            });
          }
        }
      });
      return variaveis;
    },
    variaveisDeclaradas() {
      const variaveis = [];
      this.flowchartDataProperty.nodes.forEach((node) => {
        if (
          node.ces_metadata.campos &&
          [2, 3, 18, 21].includes(node.chatbot_estagio_id)
        ) {
          node.ces_metadata.campos.forEach((campo) => {
            let tmpCampo;
            if (Array.isArray(campo)) {
              tmpCampo = campo[0];
            } else {
              tmpCampo = campo;
            }
            if (
              (tmpCampo.bindText === "cfe_variavel" && tmpCampo.value) ||
              (tmpCampo.bindText == "nome_variavel" && tmpCampo.value)
            ) {
              variaveis.push({
                text: tmpCampo.value,
                code: tmpCampo.value
              });
            }
          });
        }
      });
      return variaveis;
    },
    childrens() {
      const childrensConnections = this.flowchartDataProperty.links.filter(
        (link) => link.from === this.activeNode.id
      );
      const nodesChildrens = this.flowchartDataProperty.nodes.filter((node) =>
        childrensConnections.find((child) => child.to === node.id)
      );
      return nodesChildrens;
    },
    estagiosPai() {
      const parents = [];
      let initialParentLink = this.flowchartDataProperty.links.filter(
        (link) => link.to === this.activeNode.id
      );
      if (initialParentLink.length == 0) {
        return parents;
      }

      let initialParentNode = this.flowchartDataProperty.nodes.find(
        (node) => node.id === initialParentLink[0].from
      );
      let securityVariable = 0;

      while (initialParentNode && securityVariable < 100) {
        securityVariable++;
        parents.push(initialParentNode);

        initialParentLink = this.flowchartDataProperty.links.filter(
          (link) => link.to === initialParentNode.id
        );

        if (initialParentLink.length == 0) {
          break;
        }

        initialParentNode = this.flowchartDataProperty.nodes.find(
          (node) => node.id === initialParentLink[0].from
        );
      }

      return parents;
    }
  },
  methods: {
    async getCategories(deleted_category_id = null) {
      const res = await ApiService.get(API_LINKS.chatbot_fluxo_categoria);
      this.categories_chatbot_full_object = res.data.categorias;
      this.categories_chatbot = res.data.categorias.map((categoria) => {
        return {
          name: categoria.fla_etiqueta,
          code: categoria.fluxo_categoria_id
        };
      });
      const curr_categories = JSON.parse(
        JSON.stringify(this.categories_chatbot)
      );

      const visited_categories = {};
      for (let i = 0; i < curr_categories.length; i++) {
        const categoria = curr_categories[i];
        if (visited_categories[categoria.name]) {
          visited_categories[categoria.name] =
            visited_categories[categoria.name] + 1;
          curr_categories[i].name = `${categoria.name} (${
            visited_categories[categoria.name]
          })`;
        } else {
          visited_categories[categoria.name] = 1;
        }
      }

      this.categories_chatbot = curr_categories;

      if (deleted_category_id) {
        this.selected_categories = this.selected_categories.filter(
          (categoria) => categoria.code !== deleted_category_id
        );
      }

      return curr_categories;
    },
    openChatbotCategoryModal() {
      this.$modal.show("modal-categories-chatbot");
    },
    findChilds(parent_id) {
      const childs = this.flowchartDataProperty.links.filter(
        (link) => link.from === parent_id
      );
      const nodes = this.flowchartDataProperty.nodes.filter((node) =>
        childs.find((child) => child.to === node.id)
      );
      return nodes;
    },
    async pegarCategorias() {
      const response = await ApiService.get(
        API_LINKS.chatbot_fluxo_estagio + "/categoria"
      );
      response.data.categorias.forEach((categoria) => {
        if (categoria.cec_ativo) {
          this.categorias.push({
            ...categoria,
            text: categoria.cec_nome,
            value: categoria.chatbot_estagio_categoria_id
          });
        }
      });
      this.categorias.unshift({
        text: "Selecione uma categoria",
        value: null
      });
      return response.data.categorias;
    },
    async testarFluxo() {
      await this.salvarFluxo(false, true);
      let fluxo_id = this.fluxo_teste_id;
      const usuario = JSON.parse(localStorage.getItem("usuario"));
      const payload = {
        conta_id: usuario.conta_id,
        fluxo_id
      };

      window.$chatwoot.setCustomAttributes(payload);
      window.$chatwoot.toggle();
      window.$chatwoot.toggle("open");
    },
    hideChatwoot() {
      if (window.$chatwoot) {
        try {
          const exapnded_text = document.getElementById(
            "woot-widget--expanded__text"
          );
          if (exapnded_text) exapnded_text.style.display = "none";
          const widget_bubble_icon = document.getElementById(
            "woot-widget-bubble-icon"
          );
          if (widget_bubble_icon) widget_bubble_icon.style.margin = "14px";
          const widget_bubble = document.querySelector(".woot-widget-bubble");
          if (widget_bubble) widget_bubble.style.height = "unset";
        } catch (error) {
          console.error(error);
        }
      }
    },
    showChatwoot() {
      if (window.$chatwoot) {
        try {
          const exapnded_text = document.getElementById(
            "woot-widget--expanded__text"
          );
          if (exapnded_text) exapnded_text.style.display = "flex";
          const widget_bubble_icon = document.getElementById(
            "woot-widget-bubble-icon"
          );
          if (widget_bubble_icon)
            widget_bubble_icon.style.margin = "14px 8px 14px 16px";
          const widget_bubble = document.querySelector(".woot-widget-bubble");
          if (widget_bubble) widget_bubble.style.height = "48px";
        } catch (error) {
          console.error(error);
        }
      }
    },
    buscarFluxosDeChatbot() {
      ApiService.get(API_LINKS.chatbot).then((response) => {
        this.fluxosDeChatbot = response.data.fluxos;
      });
    },
    async listarRecursosAtivos() {
      const response = await ApiService.get(API_LINKS.recursosAtivos);

      if (response.data.status !== "success") return;
      this.recursosDoUsuario = response.data.data;
      return response;
    },
    colarFluxo() {
      Swal.fire({
        title: "Deseja colar o fluxo?",
        text: "Você não poderá desfazer esta ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, colar fluxo!",
        cancelButtonText: "Cancelar"
      }).then(async (result) => {
        if (result.value) {
          const text = await navigator.clipboard.readText();
          this.flowchartDataProperty = JSON.parse(text);
          const biggest_id = this.maiorIdDosNodes(
            this.flowchartDataProperty.nodes
          );
          this.initial_id = biggest_id + 1;
        }
      });
    },
    maiorIdDosNodes(nodes) {
      let maior_id = 0;
      nodes.forEach((node) => {
        if (node.id > maior_id) {
          maior_id = node.id;
        }
      });
      return maior_id;
    },
    duplicarFluxoSelecionado() {
      this.$toast.add({
        severity: "info",
        summary: "Em construção",
        detail: "Essa funcionalidade ainda está em construção",
        life: 3000
      });
    },
    async pegarChatbotCallbacks() {
      const response = await ApiService.get(API_LINKS.chatbot_possibilidade);
      this.chatbotCallbacks = response.data.callbacks;
      return response;
    },
    substituirFluxoAtualPeloPreset(nodes, links) {
      this.flowchartDataProperty.nodes = nodes;
      this.flowchartDataProperty.links = links;
      const maior_id = this.maiorIdDosNodes(nodes);
      this.initial_id = maior_id + 1;
    },
    mostrarModalPresets() {
      this.$modal.show("modal-presets");
    },
    async pegarAgendas() {
      const recursoAgenda = this.recursosDoUsuario.find(
        (recurso) => recurso.recurso_id == 9
      );
      if (recursoAgenda) {
        const response = await ApiService.get(API_LINKS.agendas);
        this.agendas = response.data.data;
        return response;
      }
      this.agendas = [{ id: 0, nome: "Sem permissão" }];
      return null;
    },
    abrirModalAjudaFluxo() {
      this.$modal.show("modal-ajuda-chatbot");
    },
    pegarDaStackFlowchartDataProperty() {
      if (this.stackFlowchartDataProperty.length > 0) {
        this.flag_stack = false;
        this.redoStackFlowchartDataProperty.push(
          this.stackFlowchartDataProperty[
            this.stackFlowchartDataProperty.length - 1
          ]
        );
        this.flowchartDataProperty = this.stackFlowchartDataProperty.pop();
        this.$forceUpdate();
        this.flag_stack = true;
      }
    },
    pegarDaRedoStackFlowchartDataProperty() {
      if (this.redoStackFlowchartDataProperty.length > 0) {
        this.flag_stack = false;
        this.stackFlowchartDataProperty.push(
          this.redoStackFlowchartDataProperty[
            this.redoStackFlowchartDataProperty.length - 1
          ]
        );
        this.flowchartDataProperty = this.redoStackFlowchartDataProperty.pop();

        this.flag_stack = true;
      }
    },
    abrirModalInformacoesEstagio() {
      this.toastEmConstrucao();
    },
    enviarErroParaNathan() {
      const a = document.createElement("a");
      const link = `https://web.whatsapp.com/send?phone=557591485970&text=Olá, Nathan! Tudo bem? Estou com um problema ao salvar o fluxo. Segue abaixo o erro: ${JSON.stringify(
        this.flowchartDataProperty
      )}`;
      a.href = link;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    removerNodeAtivo() {
      this.sidebarVisible = false;

      this.flowchartDataProperty.nodes =
        this.flowchartDataProperty.nodes.filter(
          (node) => node.id !== this.activeNode.id
        );
      this.flowchartDataProperty.links =
        this.flowchartDataProperty.links.filter(
          (link) =>
            link.from !== this.activeNode.id && link.to !== this.activeNode.id
        );
      this.activeNode = {};
    },
    eventoDeZoomNoScroll() {
      const flowChartContainer = document.querySelector(".flowchart-container");

      flowChartContainer.addEventListener("wheel", (event) => {
        const mouseEvent = window.event || event;
        if (
          mouseEvent.clientX > flowChartContainer.offsetLeft &&
          mouseEvent.clientX <
            flowChartContainer.offsetLeft + flowChartContainer.offsetWidth &&
          mouseEvent.clientY > flowChartContainer.offsetTop &&
          mouseEvent.clientY <
            flowChartContainer.offsetTop + flowChartContainer.offsetHeight
        ) {
          event.preventDefault();
          event.stopPropagation();

          const delta = Math.max(
            -1,
            Math.min(1, event.wheelDelta || -event.detail)
          );

          if (delta > 0) {
            this.zoomIn();
          } else {
            this.zoomOut();
          }
        }
      });
    },
    copiarFluxo(propriedade) {
      if (propriedade) {
        navigator.clipboard.writeText(
          JSON.stringify(this.flowchartDataProperty[propriedade])
        );
      } else {
        navigator.clipboard.writeText(
          JSON.stringify(this.flowchartDataProperty)
        );
      }

      this.$toast.add({
        severity: "success",
        summary: "Copiado",
        detail: `Fluxo copiado para área de transferência`,
        life: 7000
      });
    },
    bindNasTeclas() {
      // What is mousetrap?
      // Mousetrap is a simple library for handling keyboard shortcuts in Javascript.
      // There is Mousetrap for vuejs?
      // Yes, there is a vuejs mousetrap
      this.$mousetrap.bind("ctrl+alt+s", this.salvarFluxo);
      this.$mousetrap.bind("ctrl+alt+h", this.logarTodosOsAtalhos);
      this.$mousetrap.bind("ctrl+alt+n", this.enviarErroParaNathan);
      // ctrl shift f full screen
      this.$mousetrap.bind("ctrl+alt+f", this.toggleFullscreen);
      // copy flowchartDataProperty
      this.$mousetrap.bind("ctrl+shift+c", () => this.copiarFluxo(false));
      // paste flowchartDataProperty
      this.$mousetrap.bind("ctrl+shift+v", this.colarFluxo);
      // copy only nodes
      this.$mousetrap.bind("ctrl+shift+alt+n", () => this.copiarFluxo("nodes"));
      this.$mousetrap.bind("ctrl+shift+alt+l", () => this.copiarFluxo("links"));
      // On delete remove active node
      this.$mousetrap.bind("del", this.removerNodeAtivo);
      // stack
      this.$mousetrap.bind("ctrl+z", this.pegarDaStackFlowchartDataProperty);
      // duplicar
      this.$mousetrap.bind("ctrl+d", this.duplicateActiveNode);

      // redo stack
      this.$mousetrap.bind(
        "ctrl+shift+z",
        this.pegarDaRedoStackFlowchartDataProperty
      );
      this.$mousetrap.bind(
        "ctrl+y",
        this.pegarDaRedoStackFlowchartDataProperty
      );
      this.$mousetrap.bind("ctrl+shift+up", this.moveFlowchartUp);
      this.$mousetrap.bind("ctrl+shift+down", this.moveFlowchartDown);
      this.$mousetrap.bind("ctrl+shift+left", this.moveFlowchartLeft);
      this.$mousetrap.bind("ctrl+shift+right", this.moveFlowchartRight);
    },
    duplicateActiveNode() {
      const node = this.activeNode;
      const clone = JSON.parse(JSON.stringify(node));

      clone.chatbot_fluxo_estagio_id = null;
      clone.chatbot_fluxo_id = null;
      delete clone.chatbot_fluxo_estagio_id;
      delete clone.chatbot_fluxo_id;

      clone.label = clone.label + " - Cópia";
      clone.type = clone.type + " - Cópia";
      clone.x = clone.x + utils.random(0, 150);
      clone.y = clone.y + utils.random(0, 150);
      clone.id = this.initial_id;

      this.flowchartDataProperty.nodes.push(clone);
      this.initial_id++;
    },
    moveFlowchartUp() {
      this.flowchartDataProperty.centerY += 100;
    },
    moveFlowchartDown() {
      this.flowchartDataProperty.centerY -= 100;
    },
    moveFlowchartLeft() {
      this.flowchartDataProperty.centerX += 100;
    },
    moveFlowchartRight() {
      this.flowchartDataProperty.centerX -= 100;
    },
    logarTodosOsAtalhos() {
      console.info(
        "Atalhos: \n",
        "ctrl+alt+s salvar fluxo \n",
        "ctrl+alt+n enviar erro para Nathan \n",
        "ctrl+alt+f full screen \n",
        "ctrl+alt+i inserir todos os filhos de opções \n",
        "ctrl+shift+c copiar fluxo \n",
        "ctrl+shift+alt+n copiar nodes \n",
        "ctrl+shift+alt+l copiar links \n",
        "ctrl+z pegar da stack \n",
        "ctrl+shift+z pegar da redo stack \n",
        "ctrl+y pegar da redo stack \n",
        "ctrl+shift+up navegar no fluxo para cima \n",
        "ctrl+shift+down navegar no fluxo para baixo \n",
        "ctrl+shift+left navegar no fluxo para esquerda \n",
        "ctrl+shift+right navegar no fluxo para direita \n"
      );
    },
    copiarNodes() {
      const el = document.createElement("textarea");
      el.value = JSON.stringify(this.flowchartDataProperty.nodes);
      document.body.appendChild(el);
      el.select();

      navigator.clipboard.writeText(JSON.stringify(this.flowchartDataProperty));
      document.body.removeChild(el);
      this.$toast.add({
        severity: "success",
        summary: "Copiado",
        detail: `Fluxo copiado para área de transferência`,
        life: 7000
      });
    },
    toastEmConstrucao() {
      this.$toast.add({
        severity: "info",
        summary: "Em construção",
        detail: `Essa funcionalidade ainda não está disponível.`,
        life: 7000
      });
    },
    showModalConfiguracoes() {
      this.$modal.show("modal-configuracao-usuario");
    },
    async pegarModeloLinks() {
      ApiService.get(API_LINKS.chatbot_modelo_links).then((response) => {
        this.modeloLinks =
          response.data?.success != false ? response.data.links : [];
      });
    },
    async buscarAtributosCustomizaveis() {
      try {
        const usuario = JSON.parse(localStorage.getItem("usuario"));
        const conta_id = usuario.conta_id;
        const response = await ApiService.get(
          API_LINKS.chatwoot + `/atributos-customizaveis?conta_id=${conta_id}`
        );
        if (response.data.sucesso && response.data.messagem) {
          const atributos_customizaveis = response.data.messagem.map(
            (atributo) => {
              return {
                ...atributo,
                text: atributo.attribute_display_name,
                value: atributo.attribute_key
              };
            }
          );
          atributos_customizaveis.unshift({
            text: "Sem atributo",
            value: null,
            attribute_model: "conversation_attribute"
          });
          this.custom_attributes = atributos_customizaveis;
        } else {
          this.custom_attributes = [
            {
              text: "Sem atributo",
              value: null,
              attribute_model: "conversation_attribute"
            }
          ];
        }
      } catch (error) {
        // ...
      }
    },
    async buscarTimes() {
      ApiService.get(API_LINKS.chatbot_times)
        .then((response) => {
          this.times = response.data;

          if (!response.data.sucesso) {
            this.chatbotEstagioOpcoes = this.chatbotEstagioOpcoes.filter(
              (opcao) => opcao.value != 5
            );
          } else {
            this.times = !response.data.messagem
              ? []
              : response.data.messagem.map((time) => {
                  const timeNomeCaptalizado = time.name
                    .split(" ")
                    .map((palavra) => {
                      return palavra.charAt(0).toUpperCase() + palavra.slice(1);
                    })
                    .join(" ");
                  return {
                    value: time.id,
                    text: timeNomeCaptalizado
                  };
                });
          }
          this.times.unshift({
            value: null,
            text: "Sem time"
          });
        })
        .catch((error) => {
          // ...
        });
    },
    showModalConfirmacaoSair() {
      this.$modal.show("modal-confirmacao-sair");
    },
    breadthFirstSearchFormatter() {
      const flowchart_hashmap = {};
      for (let i = 0; i < this.flowchartDataProperty.nodes.length; i++) {
        const node = this.flowchartDataProperty.nodes[i];
        flowchart_hashmap[node.id] = node;
      }
      const visited = [];
      const queue = [];
      const ancestor = this.findAncestor();
      queue.push(ancestor);
      visited.push(ancestor);
      let level = 0;
      let current_x = -800;
      let current_y = -10;
      const node_parent_hashmap = {};
      const levels = [];
      let largest_level = 0;
      while (queue.length > 0) {
        const level_nodes = [];
        const size = queue.length;
        let level_x = current_x;
        let level_y = current_y;
        let first_level_node = null;
        largest_level = Math.max(largest_level, size);
        for (let i = 0; i < size; i++) {
          const node = queue.shift();
          level_nodes.push(node);
          if (!first_level_node) {
            first_level_node = node;
            const node_parent = node_parent_hashmap[node.id];
            if (node_parent && node_parent.x !== current_x) {
              current_x += node_parent.x;
              level_x = node_parent.x;
            }
          }
          node.x = level_x;
          node.y = level_y;
          level_x += 170;
          const links = this.flowchartDataProperty.links.filter(
            (link) => link.from === node.id
          );
          links.forEach((link) => {
            const child = flowchart_hashmap[link.to];
            node_parent_hashmap[link.to] = node;
            if (!visited.includes(child)) {
              queue.push(child);
              visited.push(child);
            }
          });
        }
        largest_level = Math.max(largest_level, size);
        levels.push(level_nodes);
        current_y += 140;
        level++;
      }

      for (let i = 0; i < levels.length; i++) {
        const level_nodes = levels[i];
        const level_size = level_nodes.length;

        const level_center = level_size / 2;
        const level_perimeter = 170 * largest_level;
        const level_start = level_center - level_size / 2;
        const level_step = level_perimeter / level_size;
        let level_x = level_start;
        if (level_size === 1) {
          const node = level_nodes[0];
          node.x = level_perimeter / 2;
          continue;
        }
        for (let j = 0; j < level_nodes.length; j++) {
          const node = level_nodes[j];
          node.x = level_x;
          level_x += level_step;
        }
      }

      this.$toast.add({
        severity: "info",
        summary: "Dica",
        detail: `Você pode centralizar o fluxo clicando no botão "🎯"`,
        life: 3000
      });

      this.$refs.CenterFlowchartBtn.classList.add("crazy-news");
      setTimeout(() => {
        this.$refs.CenterFlowchartBtn.classList.remove("crazy-news");
      }, 3000);

      return visited;
    },
    formatFlowchart() {
      const dismiss_format_animation = localStorage.getItem(
        "dismiss_format_animation"
      );
      if (!dismiss_format_animation) {
        Swal.fire({
          title: "Gostaria de silenciar a animação de formatação?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, silenciar animação de formatação!",
          cancelButtonText: "Não"
        }).then((result) => {
          if (result.value) {
            localStorage.setItem("dismiss_format_animation", true);
            this.$refs.FormatFlowchartBtn.classList.remove("news");
          }
        });
      }
      this.breadthFirstSearchFormatter();
    },
    findAncestor() {
      const lost_nodes = [];
      for (let i = 0; i < this.flowchartDataProperty.nodes.length; i++) {
        const node = this.flowchartDataProperty.nodes[i];
        const links = this.flowchartDataProperty.links.filter(
          (link) => link.to === node.id || link.from === node.id
        );
        if (links.length === 0) {
          lost_nodes.push(node.id);
        }
      }

      let ancestor = null;
      for (let i = 0; i < this.flowchartDataProperty.nodes.length; i++) {
        const node = this.flowchartDataProperty.nodes[i];
        const links = this.flowchartDataProperty.links.filter(
          (link) => link.to === node.id
        );
        if (links.length == 0 && !lost_nodes.includes(node.id)) {
          ancestor = node;
        }
      }

      return ancestor;
    },
    centerFlowchart() {
      this.flowchartDataProperty.scale = 1;
      this.flowchartDataProperty.centerX = this.findAncestor().x * -1 + 500;
      this.flowchartDataProperty.centerY = 140;
    },
    async pegarFluxoPeloId(fluxo_id) {
      const res = await ApiService.get(API_LINKS.chatbot + "/" + fluxo_id);
      return res.data.fluxo;
    },
    async recuperarDadosEdicao() {
      let dadosFluxoDosParametros;

      if (this.$route.params.fluxoObj) {
        dadosFluxoDosParametros = this.$route.params.fluxoObj;
      } else {
        dadosFluxoDosParametros = await this.pegarFluxoPeloId(
          this.$route.query.fluxo_id
        );
      }

      this.tituloFluxo = dadosFluxoDosParametros.cfl_titulo;
      this.descricaoFluxo = dadosFluxoDosParametros.cfl_descricao;
      await ApiService.get(
        API_LINKS.chatbot + "/" + dadosFluxoDosParametros.chatbot_fluxo_id
      ).then((response) => {
        const fluxo = response.data.fluxo;
        this.selected_categories = fluxo.categorias.map((categoria) => {
          return {
            name: categoria.fla_etiqueta,
            code: categoria.fluxo_categoria_id
          };
        });
        this.flowchartDataProperty.nodes = fluxo.nodes
          .map((node) => {
            node.label = node.type;
            return node;
          })
          .map((node) => {
            if (node.chatbot_estagio_id === 10) {
              node.ces_metadata.campos.sort((a, b) => {
                const campo1 = a.find(
                  (campo) => (campo.bindText = "para_chatbot_fluxo_estagio_id")
                );
                const campo2 = b.find(
                  (campo) => (campo.bindText = "para_chatbot_fluxo_estagio_id")
                );
                return campo1.value - campo2.value;
              });

              node.ces_metadata.campos.forEach((campo) => {
                const cecValor = campo.find(
                  (campo) => campo.bindText === "cec_valor"
                );
                if (cecValor && cecValor?.value == null) {
                  const cecAvancado = campo.find(
                    (campo) => campo.bindText === "cec_avancado"
                  );
                  cecAvancado.value = true;
                }
              });
            }
            return node;
          });

        this.initial_id = fluxo.link_last_id + 1;

        setTimeout(() => {
          const nodesToIterateOver =
            document.querySelectorAll(".flowchart-node");

          nodesToIterateOver.forEach((node) => {
            const nodeChatbotEstagioId = node.getAttribute(
              "chatbot_fluxo_estagio_id"
            );

            const nodeData = this.flowchartDataProperty.nodes.find(
              (n) => n.chatbot_fluxo_estagio_id == nodeChatbotEstagioId
            );
            if (nodeData) {
              node.style.background = nodeData.color;
            }
          });
        }, 20);

        setTimeout(() => {
          if (fluxo.links.length > 0) {
            this.flowchartDataProperty.links = fluxo.links;
          }
          this.redoStackFlowchartDataProperty.push(this.flowchartDataProperty);
          this.centerFlowchart();
        }, 100);
      });
    },
    verificarModoEdicao() {
      if (this.$route.query.fluxo_id || this.$route.params.fluxoObj) {
        this.modoEdicao = true;
        return true;
      }
      return false;
    },
    async pegarFormatacaoEntrada() {
      await ApiService.get(API_LINKS.chatbot_formatacao_entrada).then(
        (response) => {
          this.opcoesFormatacaoEntrada = response.data.formatacao;
        }
      );
    },
    async pegarChatbotModelosConsulta() {
      await ApiService.get(API_LINKS.chatbot_consultas).then((response) => {
        this.chatbotModelos = response.data.campanhas;
      });
    },
    toggleFullscreen() {
      this.isFullScreen = !this.isFullScreen;
      const chatbotContainer = document.querySelector(".chatbot-container");
      const svg = document.querySelector(".flowchart-container");
      svg.classList.add("active");

      if (!document.fullscreenElement) {
        chatbotContainer.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          svg.classList.remove("active");
        }
      }
    },
    zoomIn() {
      const flowChartContainer = document.querySelector(".flowchart-container");
      if (this.currentZoom < 2) {
        this.currentZoom += 0.1;
        flowChartContainer.style.transform = `scale(${this.currentZoom})`;
      }
    },
    zoomOut() {
      const flowChartContainer = document.querySelector(".flowchart-container");
      const flowChartContainerSvg = document.querySelector(
        ".flowchart-container svg"
      );
      if (this.currentZoom > 0.51) {
        this.currentZoom -= 0.1;
        flowChartContainer.style.transform = `scale(${this.currentZoom})`;
        if (this.currentZoom <= 1) {
          flowChartContainer.style.transformOrigin = "0% 0% 0px";
          flowChartContainerSvg.classList.add("fullscreen");
          flowChartContainer.style.width = "20000px";
          flowChartContainer.style.height = "10000px";
        }
      } else {
        return;
      }
    },
    nodeClick(nodeId) {
      const node = this.flowchartDataProperty.nodes.find(
        (node) => node.id === nodeId
      );
      this.activeNode = node;
    },
    nodeDelete(nodeId) {
      const node = this.flowchartDataProperty.nodes.find(
        (node) => node.id === nodeId
      );
    },
    linkBreak(obj) {
      const { id, from, to } = obj;
      const nodeFrom = this.flowchartDataProperty.nodes.find(
        (node) => node.id === from
      );
      const nodeTo = this.flowchartDataProperty.nodes.find(
        (node) => node.id === to
      );
      this.ultimoEstagioDaConexaoViaLink = nodeFrom;
      this.lastNodeToLineIteration = nodeTo;
    },
    linkAdded(obj) {
      const { id, from, to } = obj;
      const nodeFrom = this.flowchartDataProperty.nodes.find(
        (node) => node.id === from
      );
      const nodeTo = this.flowchartDataProperty.nodes.find(
        (node) => node.id === to
      );
      this.ultimoEstagioDaConexaoViaLink = nodeFrom;
      this.lastNodeToLineIteration = nodeTo;
    },
    canvasClick(event) {
      if (event.returnValue) {
        this.sidebarVisible = false;
      } else {
        this.sidebarVisible = true;
      }
    },
    removerFilhosExcedentesDeTomarDecisao() {
      const map = {};
      this.flowchartDataProperty.nodes.map((node) => {
        if (map[node.chatbot_estagio_id]) {
          map[node.chatbot_estagio_id].push(node.id);
        } else {
          map[node.chatbot_estagio_id] = [node.id];
        }
      });
      if (map[10]) {
        map[10].forEach((id) => {
          const node_tomar_decisao = this.flowchartDataProperty.nodes.find(
            (node) => node.id === id
          );
          const childs = this.flowchartDataProperty.links
            .filter((link) => link.from === node_tomar_decisao.id)
            .map((link) => link.id);
          if (node_tomar_decisao.ces_metadata.campos.length > childs.length) {
            for (
              let i = node_tomar_decisao.ces_metadata.campos.length;
              i > childs.length;
              i--
            ) {
              node_tomar_decisao.ces_metadata.campos.pop();
            }
          }
        });
      }
    },
    async salvarFluxo(redirecionar = true, fluxo_teste = false) {
      this.$forceUpdate();
      this.removerFilhosExcedentesDeTomarDecisao();
      if (this.verificacoesAtivas) {
        if (
          await this.temCamposRequiredPendentes(
            this.flowchartDataProperty.nodes
          )
        ) {
          return;
        } else if (this.flowchartDataProperty.nodes.length === 0) {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: `É necessário adicionar um estágio inicial.`,
            life: 7000
          });
          return;
        } else if (
          this.flowchartDataProperty.nodes.some((node) => {
            const parents = this.flowchartDataProperty.links.filter(
              (link) => link.to === node.id
            );
            const childrens = this.flowchartDataProperty.links.filter(
              (link) => link.from === node.id
            );
            return parents.length === 0 && childrens.length === 0;
          }) &&
          this.flowchartDataProperty.nodes.length > 1
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: `Não são permitidos estágios isolados.`,
            life: 7000
          });
          return;
        } else if (
          this.flowchartDataProperty.nodes.filter((node) => {
            const parents = this.flowchartDataProperty.links.filter(
              (link) => link.to === node.id
            );
            return parents.length === 0;
          }).length > 1
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: `Não são permitidos mais de um estágio inicial.`,
            life: 7000
          });
          return;
        }
      }
      if (this.modoEdicao) {
        await this.enviarFluxoObjetoEdicao(redirecionar, fluxo_teste);
      } else {
        this.enviarFluxoObjetoSalvar();
      }
    },
    async enviarFluxoObjetoEdicao(redirecionar, fluxo_teste = false) {
      const dadosFluxoDosParametros = this.$route.params.fluxoObj;

      const payload = {
        fluxo: {
          cfl_titulo: "",
          cfl_descricao: "",
          cfl_teste: fluxo_teste,
          chatbot_fluxo_id: null,
          estagios: {
            nodes: [],
            links: []
          },
          variaveis: {
            declaradas: [],
            utilizadas: []
          }
        }
      };

      if (!fluxo_teste) {
        payload.fluxo.categorias = this.selected_categories.map(
          (category) => category.code
        );
      }

      payload.fluxo.variaveis.declaradas = this.variaveisDeclaradas.map(
        (variavel) => variavel.text
      );
      payload.fluxo.variaveis.utilizadas = this.variaveisUtilizadas;

      payload.fluxo.chatbot_fluxo_id = dadosFluxoDosParametros
        ? dadosFluxoDosParametros.chatbot_fluxo_id
        : this.$route.query.fluxo_id;
      payload.fluxo.cfl_titulo = this.tituloFluxo;
      payload.fluxo.cfl_descricao = this.descricaoFluxo;
      payload.fluxo.estagios.nodes = this.flowchartDataProperty.nodes;
      payload.fluxo.estagios.links = this.flowchartDataProperty.links;

      if (fluxo_teste) {
        payload.fluxo.chatbot_fluxo_id = null;
        payload.fluxo.cfl_titulo = "[FLUXO_TESTE] " + payload.fluxo.cfl_titulo;
      }

      const response = await ApiService.post(API_LINKS.chatbot, payload);
      this.fluxo_teste_id = response.data.fluxo.chatbot_fluxo_id;
      const toast = {
        severity: "success",
        summary: "Sucesso",
        detail: `Fluxo "${payload.fluxo.cfl_titulo}" editado com sucesso`,
        life: 3000
      };
      if (redirecionar) {
        this.$router.push({
          name: "chatbot",
          params: {
            toast: toast
          }
        });
      }
      return response;
    },
    async temCamposRequiredPendentes(estagios) {
      let primeiro_estagio_incompleto = null;
      estagios.forEach((estagio) => {
        const campos = estagio.ces_metadata.campos;
        const ESTAGIO_TOMAR_DECISAO = estagio.chatbot_estagio_id == 10;
        if (campos.some((campo) => Array.isArray(campo))) {
          campos.forEach((campo) => {
            campo.forEach((campo) => {
              if (
                campo.required &&
                (campo.value === "" || campo.value === null)
              ) {
                if (
                  !ESTAGIO_TOMAR_DECISAO ||
                  campo.bindText == "cec_condicao"
                ) {
                  this.$toast.add({
                    severity: "error",
                    summary: "Campos Pendentes!",
                    detail: `Preecha campos pendentes do estágio {${estagio.label}}`,
                    life: 4500
                  });
                  if (!primeiro_estagio_incompleto) {
                    primeiro_estagio_incompleto = estagio;
                  }
                }
              }
            });
          });
        } else {
          campos.forEach((campo) => {
            if (
              campo.required &&
              (campo.value === "" || campo.value === null)
            ) {
              this.flowchartDataProperty.centerX = estagio.x * -1 + 150;
              this.flowchartDataProperty.centerY = estagio.y * -1 + 150;

              this.$toast.add({
                severity: "error",
                summary: "Campos Pendentes!",
                detail: `Preecha campos pendentes do estágio {${estagio.label}}`,
                life: 4500
              });
              if (!primeiro_estagio_incompleto) {
                primeiro_estagio_incompleto = estagio;
              }
            }
          });
        }
      });
      const flowchart_container = document.querySelector(
        ".flowchart-container"
      );
      if (primeiro_estagio_incompleto) {
        await new Promise((resolve) => setTimeout(resolve, 250));
        this.activeNode = primeiro_estagio_incompleto;
        this.sidebarVisible = true;
        const corner_left = flowchart_container.offsetLeft + 152;
        const corner_top = flowchart_container.offsetTop + 152;
        const ele = document.elementFromPoint(corner_left, corner_top);
        const interval = setInterval(() => {
          ele.classList.toggle("selected");
        }, 100);
        setTimeout(() => {
          clearInterval(interval);
          ele.classList.remove("selected");
        }, 5000);
        return true;
      }

      return false;
    },
    enviarFluxoObjetoSalvar() {
      const payload = {
        fluxo: {
          cfl_titulo: "",
          cfl_descricao: "",
          cfl_teste: false,
          chatbot_fluxo_id: null,
          estagios: {
            nodes: [],
            links: []
          },
          variaveis: {
            declaradas: [],
            utilizadas: []
          }
        }
      };

      payload.fluxo.categorias = this.selected_categories.map(
        (category) => category.code
      );

      payload.fluxo.variaveis.declaradas = this.variaveisDeclaradas.map(
        (variavel) => variavel.text
      );
      payload.fluxo.variaveis.utilizadas = this.variaveisUtilizadas;
      payload.fluxo.cfl_titulo = this.tituloFluxo;
      payload.fluxo.cfl_descricao = this.descricaoFluxo;
      payload.fluxo.estagios.nodes = this.flowchartDataProperty.nodes;
      payload.fluxo.estagios.links = this.flowchartDataProperty.links;

      ApiService.post(API_LINKS.chatbot, payload).then((response) => {
        const toast = {
          severity: "success",
          summary: "Sucesso",
          detail: "Fluxo salvo com sucesso",
          life: 3000
        };

        this.$router.push({
          name: "chatbot",
          params: {
            toast: toast
          }
        });
      });
    },

    addNode(chatbot_estagio_id = null) {
      const nodeToAdd = JSON.parse(
        JSON.stringify(
          this.chatbotEstagios.find(
            (item) =>
              item.chatbot_estagio_id ===
              (chatbot_estagio_id
                ? chatbot_estagio_id
                : this.chatbotEstagioSelecionado)
          )
        )
      );

      nodeToAdd.x =
        (this.flowchartDataProperty.centerX / 2) * -1 + utils.random(50, 100);
      nodeToAdd.y =
        this.flowchartDataProperty.centerY * -1 + 200 + utils.random(50, 100);

      this.lastXCordinate += utils.random(50, 100);
      this.lastYCordinate += utils.random(50, 100);
      if (this.lastXCordinate > -700) {
        this.lastXCordinate = -800;
      }
      if (this.lastYCordinate > 0) {
        this.lastYCordinate = -100;
      }
      nodeToAdd.type = this.initial_id + " - " + nodeToAdd.ces_nome;

      nodeToAdd.label = this.initial_id + " - " + nodeToAdd.ces_nome;

      nodeToAdd.id = this.initial_id;
      this.initial_id++;

      this.flowchartDataProperty.nodes.push(nodeToAdd);
      setTimeout(() => {
        const lastChild = document.querySelector(
          ".flowchart-container"
        ).lastChild;
        lastChild.style.background = nodeToAdd.cor;
        const button = document.createElement("button");

        button.style.width = "100%";
        button.style.background = "#1faeef";
        button.style.marginTop = "auto";
        button.innerHTML = "Propriedades";

        button.onclick = () => {
          this.$modal.show("modal-estagio");
        };
        lastChild.style.display = "flex";
        lastChild.style.flexDirection = "column";
        lastChild.style.justifyContent = "space-between";
        lastChild.style.minHeight = "75px";
      }, 10);
    },
    async pegarEstagios() {
      await ApiService.get(API_LINKS.chatbot_fluxo_estagio).then((response) => {
        this.chatbotEstagios = response.data.estagios.map((estagio) => {
          estagio.ces_metadata = JSON.parse(estagio.ces_metadata);
          if (estagio.chatbot_estagio_id == 10) {
            estagio.ces_metadata.campos = [];
          }
          return estagio;
        });
        this.chatbotCampanhas = response.data.campanhas;
        this.chatbotSistemas = response.data.sistemas;

        this.chatbotEstagioOpcoes = this.chatbotEstagios.map((estagio, idx) => {
          estagio.cor = estagio.ces_metadata.cor;
          return {
            value: estagio.chatbot_estagio_id,
            text: estagio.ces_nome
          };
        });

        this.chatbotEstagioOpcoes.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
      });
    }
  },
  mounted() {
    window.chatwootRemoveWidgets();
    window.chatwootLaunchForChatbot();
    let newConfig = JSON.parse(JSON.stringify(config));
    this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
    newConfig.styleContetnt = { padding: "0" };
    newConfig.header.self.display = false;
    newConfig.subheader.display = false;
    newConfig.styleContainer = { "max-width": "100%", margin: 0 };
    newConfig.aside.self.minimize.hoverable = true;
    this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);
    this.hideChatwoot();
    const dismiss_format_animation = localStorage.getItem(
      "dismiss_format_animation"
    );
    if (dismiss_format_animation) {
      this.$refs.FormatFlowchartBtn.classList.remove("news");
    }

    const init = async () => {
      this.eventoDeZoomNoScroll();
      // this.logarTodosOsAtalhos();
      this.getCategories();
      this.buscarFluxosDeChatbot();
      await this.pegarCategorias();
      await this.listarRecursosAtivos();
      await this.pegarEstagios();
      await this.pegarChatbotModelosConsulta();
      await this.pegarFormatacaoEntrada();
      await this.buscarTimes();
      await this.buscarAtributosCustomizaveis();
      await this.pegarAgendas();
      await this.pegarModeloLinks();
      await this.pegarChatbotCallbacks();
      const _sistemas = await ApiService.get(API_LINKS.sistemaLicenciada);
      this.sistemas = _sistemas.data.sistemas;
      this.verificarModoEdicao();
      if (this.modoEdicao) {
        this.recuperarDadosEdicao();
      } else {
        this.stackFlowchartDataProperty.push(
          JSON.parse(JSON.stringify(this.flowchartDataProperty))
        );
      }
      const possui_recurso_agendamento = this.recursosDoUsuario.some(
        (recurso) => {
          return recurso.recurso_id == 9;
        }
      );
      if (!possui_recurso_agendamento) {
        this.chatbotEstagioOpcoes = this.chatbotEstagioOpcoes.filter(
          (estagio) => {
            // 17 e 20 são ids de estagios de agendamento
            return ![17, 20].includes(estagio.value);
          }
        );
      }
      if (localStorage.getItem("usuario_config")) {
        this.usuario_config = JSON.parse(
          localStorage.getItem("usuario_config")
        );

        const zoom = this.usuario_config.default_initial_zoom;
        if (zoom > 1) {
          for (let i = 1; i < zoom; i += 0.1) {
            this.zoomIn();
          }
        } else if (zoom < 1) {
          for (let i = 1; i > zoom; i -= 0.1) {
            this.zoomOut();
          }
        }
      }
    };

    init();
    this.bindNasTeclas();
  },
  beforeDestroy() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
    window.chatwootRemoveWidgets();
    window.chatwootLaunchForSupport();
  }
};
</script>

<style lang="scss" scoped>
.chatbot-container {
  margin-bottom: 32px;
  @media (min-width: 768px) {
    margin-top: -32px !important;
  }
  background: #fff;
  border-radius: 10px;
  .chatbot-header {
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 20px;
    .top-header {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
    }
    .text-title {
      margin-bottom: 4px;
    }
    .flexer {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .botoes-iteracao-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  button {
    text-transform: uppercase;
  }

  @keyframes crazy-pulse {
    0% {
      transform: scale(0.9);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.7);
    }
    70% {
      transform: scale(1.1);
      box-shadow: 0 0 0 10px rgba(255, 140, 0, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.9);
    }
    70% {
      box-shadow: 0 0 0 15px rgba(255, 140, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
    }
  }
  .news {
    animation: pulse 0.2s infinite;
    &:hover {
      animation: none;
    }
  }

  .crazy-news {
    animation: crazy-pulse 1s infinite;
  }

  .fluxo-container {
    .fluxo-header {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;

      margin-bottom: 20px;
      .flexer {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}
.camp-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e6ef;
  padding: 10px 20px;
  align-items: center;
}
.back-arrow-squared {
  border: 1px solid #808080;
  border-radius: 4px;
  transform: rotate(-90deg);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stage-btn {
  background: rgb(225, 255, 177) !important;
  border-color: rgb(225, 255, 177) !important;
  color: #191919;
  &:hover,
  &:focus {
    background: darken(rgb(225, 255, 177), 50%) !important;
    border-color: darken(rgb(225, 255, 177), 50%) !important;
  }
  &.decision-type {
    background: rgb(255, 246, 191) !important;
    border-color: rgb(255, 246, 191) !important;
    color: #191919;
    &:hover,
    &:focus {
      background: darken(rgb(255, 246, 191), 50%) !important;
      border-color: darken(rgb(255, 246, 191), 50%) !important;
      color: #191919;
    }
  }
  &.finish-type {
    background: #ff6464 !important;
    border-color: #ff6464 !important;
    color: #fff;
    &:hover,
    &:focus {
      background: darken(#ff6464, 50%) !important;
      border-color: darken(#ff6464, 50%) !important;
    }
  }
}
.orange-btn {
  background: #ff8c00;
  border-color: #ff8c00;
  color: #fff !important;
  &:hover,
  &:focus {
    background: darken(#ff8c00, 10%) !important;
    border-color: darken(#ff8c00, 10%) !important;
    color: #fff !important;
  }
}
::v-deep .flowchart-container svg g {
  transform: translateX(10px) !important;
}
::v-deep .flowchart-node {
  height: fit-content;
  min-height: 75px;
  width: 100px;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  .node-label {
    font-size: 12px !important;
  }
}

::v-deep .node-delete {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .flowchart-container svg {
  height: 600px !important;
  &.fullscreen {
    height: 10000px !important;
  }
}

::v-deep .flowchart-node {
  background: rgb(225, 255, 177) !important;
  &[ces_estagio="CONDICAO"],
  &[ces_estagio="VALIDACAO"] {
    background: rgb(255, 246, 191) !important;
  }
  &[ces_estagio="MATAR_PROCESSO"] {
    background: #ff6464 !important;
    color: #fff !important;
  }
}

::v-deep .flowchart-container.active svg {
  height: 300vh !important;
}

::v-deep .flowchart-node .node-main .node-type {
  background: #ff8c00 !important;

  border-radius: 8px !important;
}
::v-deep .selected {
  border-color: #ff8c00;
}
::v-deep .node-main .node-type {
  display: none !important;
}
::v-deep .node-main {
  margin: auto;
}
</style>
