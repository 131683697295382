<template>
  <modal name="modal-categories-chatbot" scrollable :width="400" height="auto">
    <div class="modal-categories-chatbot">
      <h2 class="title text-center">Categorias</h2>
      <br />
      <div class="d-flex align-items-center flex-wrap" style="gap: 1rem">
        <b-badge
          v-for="category in categories"
          class="category-badge"
          :key="'category-' + category.fluxo_categoria_id"
          :style="'background: ' + category.fla_cor + ' !important'"
        >
          <span>
            {{ category.fla_etiqueta }}
          </span>
          <i
            class="fas fa-times ml-2"
            style="font-size: 12px; color: inherit; cursor: pointer"
            @click="deleteCategory(category.fluxo_categoria_id)"
          >
          </i>
        </b-badge>
      </div>

      <br />
      <br />
      <div>
        <h3 class="title text-center">Nova Categoria</h3>
        <br />
        <div class="d-flex flex-column">
          <div class="form-group">
            <label for="CategoryName">Nome da Categoria</label>
            <input
              type="text"
              class="form-control"
              id="CategoryName"
              placeholder="Nome da Categoria"
              v-model="new_cateogry_obj.fla_etiqueta"
            />
          </div>

          <button
            class="btn btn-primary w-100"
            style="
              background: #ffa800 !important;
              border-color: #ffa800 !important;
            "
            @click="saveCategory"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Swal from "sweetalert2";
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      colors_from_pallete: [
        "#faedcb",
        "#c9e4de",
        "#c6def1",
        "#dbcdf0",
        "#f2c6de",
        "#f7d9c4",
        "#ffadad",
        "#ffd6a5",
        "#fdffb6",
        "#e4f1ee",
        "#d9edf8",
        "#dedaf4",
        "#fa9bcf",
        "#b0b5ed",
        "#f9dc5c",
        "#fae7eb",
        "#f2d7e8",
        "#ecf8f7",
        "#b58ebc",
        "#d8bbd3",
        "#d1edea",
        "#D67BFF",
        "#FEFFAC"
      ],
      new_cateogry_obj: {
        fla_etiqueta: "",
        fla_color: ""
      }
    };
  },
  methods: {
    deleteCategory(id) {
      Swal.fire({
        title: "Você tem certeza?",
        text: "Você não poderá reverter esta ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ffa800",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, delete a categoria!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ApiService.delete(API_LINKS.chatbot_fluxo_categoria + "/" + id);
          this.$emit("refreshCategories", id);
        }
      });
    },
    async saveCategory() {
      const payload = {
        categoria: {
          fla_etiqueta: this.new_cateogry_obj.fla_etiqueta
        }
      };

      payload.categoria.fla_cor = this.findNewAvailableColor();
      await ApiService.post(API_LINKS.chatbot_fluxo_categoria, payload);
      this.$emit("refreshCategories");
    },
    findNewAvailableColor() {
      const used_colors = this.current_selected_colors;

      for (let i = 0; i < this.colors_from_pallete.length; i++) {
        const color = this.colors_from_pallete[i];
        if (!used_colors.includes(color)) {
          return color;
        }
      }

      return this.colors_from_pallete[
        Math.floor(Math.random() * this.colors_from_pallete.length)
      ];
    }
  },
  mounted() {},
  computed: {
    current_selected_colors() {
      const used_colors = this.categories.map((categoria) => {
        return categoria.fla_cor;
      });
      return used_colors;
    }
  }
};
</script>

<style lang="scss" scoped>
.category-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d3b40;
}
.modal-categories-chatbot {
  padding: 24px !important;
}
</style>
