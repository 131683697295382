<template>
  <modal
    name="modal-estagio"
    class="modal-container"
    scrollable
    height="auto"
    width="900"
  >
    <div class="modal-chatbot-info">
      <h2>{{ estagioAtivo }}</h2>
      <p>
        {{ estagioAtivo }}
      </p>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    estagioAtivo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-chatbot-info {
  padding: 24px !important;

  h2 {
    /* margin: 0; */
  }
}
</style>
