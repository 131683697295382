<template>
  <modal name="modal-presets" scrollable :width="'750'" height="auto">
    <div class="modal-presets">
      <!-- <div  class="botao-fechar">
          <button @click="$modal.hide('modal-presets')">❌</button>
        </div> -->

      <h2 class="title text-center">Templates</h2>
      <br />
      <div class="presets-container">
        <!-- There will be six buttons like radio buttons-->
        <!-- Agendamento, Orçamento, Dúvidas, Protocolo, Admin, Reclamcao -->

        <div class="flexer-container presets">
          <b-button
            variant="primary"
            :class="itemSelecionado == 1 ? 'active' : ''"
            @click="selecionarPresetAgendamento"
          >
            Agendamento
          </b-button>
          <b-button
            variant="primary"
            :class="itemSelecionado == 2 ? 'active' : ''"
            @click="selecionarPresetOrcamento"
          >
            Orçamento
          </b-button>
          <b-button
            variant="primary"
            :class="itemSelecionado == 3 ? 'active' : ''"
            @click="selecionarPresetDuvidas"
          >
            Dúvidas
          </b-button>
          <b-button
            variant="primary"
            :class="itemSelecionado == 4 ? 'active' : ''"
            @click="selecionarPresetProtocolo"
          >
            Protocolo
          </b-button>
          <b-button
            variant="primary"
            :class="itemSelecionado == 6 ? 'active' : ''"
            @click="selecionarPresetReclamacao"
          >
            Reclamação
          </b-button>
        </div>
      </div>
      <br />
      <div class="descricao-preset">
        <p>{{ presetAtivo.descricao }}</p>
      </div>
      <br />
      <div class="buttons-confirmar-presets">
        <b-button variant="success" @click="confirmarPreset" class="w-100">
          Confirmar
        </b-button>

        <b-button
          variant="danger"
          @click="$modal.hide('modal-presets')"
          class="w-100"
        >
          Cancelar
        </b-button>
      </div>
    </div>
  </modal>
</template>

<script>
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      menu: {},
      itemSelecionado: -1,
      presetsOpcoes: [
        {
          id: 1,
          nome: "Agendamento",
          descricao:
            "O preset de agendamento é um preset que permite ao usuário agendar uma consulta com o médico.",
          nodes: [
            {
              id: 1,
              x: -689,
              y: -62,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3756,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "0-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value: "Qual o seu nome completo?",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "1 - Enviar Mensagem",
              label: "1 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 2,
              x: -865,
              y: 306,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3757,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "1-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Envie a foto da requisição médica ou informe-nos o que deseja.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "2 - Enviar Mensagem",
              label: "2 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 3,
              x: -828.5,
              y: 7,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3758,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "2-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "nomeCliente",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "Nome do cliente.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "3 - Aguardar Mensagem de Texto",
              label: "3 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 4,
              x: -744,
              y: 272,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3759,
              chatbot_estagio_id: 10,
              ces_estagio: "CONDICAO",
              ces_nome: "3-Tomar Decisão",
              ces_descricao: "Tomar Decisão",
              ces_visivel: true,
              ces_metadata: {
                cor: "#FFF6BF",
                qtd_max_filho: 10,
                qtd_min_filho: 2,
                proximo_estagio_id: null,
                filho_anterior: true,
                campos: [
                  [
                    {
                      label: "Estágio que a condição leva",
                      value: 2,
                      bindText: "para_chatbot_fluxo_estagio_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Condição que leva ao estágio",
                      value: "{planoTipo} === 1",
                      bindText: "cec_condicao",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Ordem de verificação dessa condição",
                      value: 2,
                      bindText: "cec_ordem",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Parâmetro",
                      value: "planoTipo",
                      bindText: "cec_parametro",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Operador",
                      value: "===",
                      bindText: "cec_operador",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Valor",
                      value: "1",
                      bindText: "cec_valor",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Avançado",
                      onLabel: "Modo Padrão",
                      offLabel: "Modo Avançado",
                      value: true,
                      bindText: "cec_avancado",
                      type: "bool",
                      required: true
                    }
                  ],
                  [
                    {
                      label: "Estágio que a condição leva",
                      value: 14,
                      bindText: "para_chatbot_fluxo_estagio_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Condição que leva ao estágio",
                      value: "{planoTipo} === 2",
                      bindText: "cec_condicao",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Ordem de verificação dessa condição",
                      value: 14,
                      bindText: "cec_ordem",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Parâmetro",
                      value: "planoTipo",
                      bindText: "cec_parametro",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Operador",
                      value: "===",
                      bindText: "cec_operador",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Valor",
                      value: "2",
                      bindText: "cec_valor",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Avançado",
                      onLabel: "Modo Padrão",
                      offLabel: "Modo Avançado",
                      value: null,
                      bindText: "cec_avancado",
                      type: "bool",
                      required: true
                    }
                  ]
                ]
              },
              type: "4 - Tomar Decisão",
              label: "4 - Tomar Decisão",
              color: "#FFF6BF"
            },
            {
              id: 5,
              x: -999,
              y: 365,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3760,
              chatbot_estagio_id: 18,
              ces_estagio: "AGUARDAR_RESPOSTA",
              ces_nome: "4-Aguardar Resposta Genérica",
              ces_descricao: "Aguarda o cliente enviar um arquivo ou mensagem",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome do arquivo/mensagem",
                    value: "requisicaoMedicaParticular",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value:
                      "Foto da requisição médica ou descrição de solicitação.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "5 - Aguardar Resposta Genérica",
              label: "5 - Aguardar Resposta Genérica",
              color: "#C7F2A4"
            },
            {
              id: 6,
              x: -500,
              y: 353,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3761,
              chatbot_estagio_id: 18,
              ces_estagio: "AGUARDAR_RESPOSTA",
              ces_nome: "5-Aguardar Resposta Genérica",
              ces_descricao: "Aguarda o cliente enviar um arquivo ou mensagem",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome do arquivo/mensagem",
                    value: "imagemConvenio",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value:
                      "Foto da requisição médica ou descrição de solicitação.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "6 - Aguardar Resposta Genérica",
              label: "6 - Aguardar Resposta Genérica",
              color: "#C7F2A4"
            },
            {
              id: 7,
              x: -858.5,
              y: 433,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3762,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "6-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value: "Em qual data você gostaria de ser atendido?",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "7 - Enviar Mensagem",
              label: "7 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 8,
              x: -855.5,
              y: 559,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3763,
              chatbot_estagio_id: 5,
              ces_estagio: "REDIRECIONAR_LIVECHAT",
              ces_nome: "7-Redirecionar para o Atende",
              ces_descricao: "Redirecionar para o Atende e finaliza o processo",
              ces_visivel: true,
              ces_metadata: {
                cor: "#B6E388",
                qtd_max_filho: 0,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Time que receberá o pedido do atendimento",
                    value: "-1",
                    bindText: "cfe_time",
                    type: "select",
                    required: false
                  },
                  {
                    label: "Mensagem que será enviada ao atendente",
                    value: "Requisição de agendamento Particular",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Parâmetros enviadas ao atendente",
                    value: [
                      { text: "planoTipo", code: "planoTipo" },
                      { text: "nomeCliente", code: "nomeCliente" },
                      {
                        text: "dataAtendimentoConvenio",
                        code: "dataAtendimentoConvenio"
                      },
                      {
                        text: "dataAtendimentoParticular",
                        code: "dataAtendimentoParticular"
                      },
                      {
                        text: "requisicaoMedicaParticular",
                        code: "requisicaoMedicaParticular"
                      },
                      { text: "imagemConvenio", code: "imagemConvenio" }
                    ],
                    bindText: "cfe_atributo",
                    type: "multiSelect",
                    required: false
                  }
                ]
              },
              type: "8 - Redirecionar para o Atende",
              label: "8 - Redirecionar para o Atende",
              color: "#B6E388"
            },
            {
              id: 9,
              x: -621.5,
              y: 550,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3764,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "8-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value: "Em qual data você gostaria de ser atendido?",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "9 - Enviar Mensagem",
              label: "9 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 10,
              x: -499,
              y: 586,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3765,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "9-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "dataAtendimentoConvenio",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "Data que o paciente gostaria de ser atendido.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "10 - Aguardar Mensagem de Texto",
              label: "10 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 11,
              x: -990,
              y: 499,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3766,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "10-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "dataAtendimentoParticular",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "Data que o paciente gostaria de ser atendido.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "11 - Aguardar Mensagem de Texto",
              label: "11 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 12,
              x: -681.5,
              y: 75,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3767,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "11-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Qual o tipo do seu plano?\n1 - Particular\n2 - Convênio",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "12 - Enviar Mensagem",
              label: "12 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 13,
              x: -618.5,
              y: 668,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3768,
              chatbot_estagio_id: 5,
              ces_estagio: "REDIRECIONAR_LIVECHAT",
              ces_nome: "12-Redirecionar para o Atende",
              ces_descricao: "Redirecionar para o Atende e finaliza o processo",
              ces_visivel: true,
              ces_metadata: {
                cor: "#B6E388",
                qtd_max_filho: 0,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Time que receberá o pedido do atendimento",
                    value: "-1",
                    bindText: "cfe_time",
                    type: "select",
                    required: false
                  },
                  {
                    label: "Mensagem que será enviada ao atendente",
                    value: "Veio do fluxo de agendamento. Plano convênio.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Parâmetros enviadas ao atendente",
                    value: [
                      { text: "planoTipo", code: "planoTipo" },
                      { text: "nomeCliente", code: "nomeCliente" },
                      {
                        text: "dataAtendimentoConvenio",
                        code: "dataAtendimentoConvenio"
                      },
                      {
                        text: "dataAtendimentoParticular",
                        code: "dataAtendimentoParticular"
                      },
                      { text: "imagemConvenio", code: "imagemConvenio" },
                      {
                        text: "requisicaoMedicaParticular",
                        code: "requisicaoMedicaParticular"
                      }
                    ],
                    bindText: "cfe_atributo",
                    type: "multiSelect",
                    required: false
                  }
                ]
              },
              type: "13 - Redirecionar para o Atende",
              label: "13 - Redirecionar para o Atende",
              color: "#B6E388"
            },
            {
              id: 14,
              x: -627.5,
              y: 312,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3769,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "13-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Envie a foto da requisição médica ou informe-nos o que deseja.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "14 - Enviar Mensagem",
              label: "14 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 15,
              x: -628,
              y: 419,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3770,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "14-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Envie a foto ou digite o código da sua carteira de convênio.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "15 - Enviar Mensagem",
              label: "15 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 16,
              x: -832.5,
              y: 121,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3771,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "15-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "planoTipo",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "Tipo do plano do cliente",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "16 - Aguardar Mensagem de Texto",
              label: "16 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 17,
              x: -505,
              y: 473,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3772,
              chatbot_estagio_id: 18,
              ces_estagio: "AGUARDAR_RESPOSTA",
              ces_nome: "16-Aguardar Resposta Genérica",
              ces_descricao: "Aguarda o cliente enviar um arquivo ou mensagem",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome do arquivo/mensagem",
                    value: "requisicaoMedicaConvenio",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "Foto ou código da carteira de convênio do cliente.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "17 - Aguardar Resposta Genérica",
              label: "17 - Aguardar Resposta Genérica",
              color: "#C7F2A4"
            },
            {
              id: 18,
              x: -644,
              y: 181,
              chatbot_fluxo_id: 740,
              chatbot_fluxo_estagio_id: 3773,
              chatbot_estagio_id: 11,
              ces_estagio: "VALIDACAO",
              ces_nome: "17-Validar Informação",
              ces_descricao: "Valida uma informação",
              ces_visivel: true,
              ces_metadata: {
                cor: "#FFF6BF",
                qtd_max_filho: 1,
                qtd_min_filho: 1,
                proximo_estagio_id: null,
                filho_anterior: true,
                campos: [
                  {
                    label: "Fluxo se a condição falhar",
                    value: 12,
                    bindText: "para_chatbot_fluxo_estagio_id",
                    type: "select",
                    required: true
                  },
                  {
                    label: "Condição para continuar",
                    value: "{planoTipo} === 1 || {planoTipo} === 2",
                    bindText: "cec_condicao",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Corpo da Mensagem",
                    value: "Nao entendi",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: true
                  }
                ]
              },
              type: "18 - Validar Informação",
              label: "18 - Validar Informação",
              color: "#FFF6BF"
            }
          ],
          links: [
            { id: 1, from: 4, to: 2 },
            { id: 2, from: 1, to: 3 },
            { id: 3, from: 18, to: 4 },
            { id: 4, from: 2, to: 5 },
            { id: 5, from: 14, to: 6 },
            { id: 6, from: 5, to: 7 },
            { id: 7, from: 11, to: 8 },
            { id: 8, from: 17, to: 9 },
            { id: 9, from: 9, to: 10 },
            { id: 10, from: 7, to: 11 },
            { id: 11, from: 3, to: 12 },
            { id: 12, from: 10, to: 13 },
            { id: 13, from: 4, to: 14 },
            { id: 14, from: 6, to: 15 },
            { id: 15, from: 12, to: 16 },
            { id: 16, from: 15, to: 17 },
            { id: 17, from: 16, to: 18 }
          ]
        },
        {
          id: 2,
          nome: "Orçamento",
          descricao:
            "O preset de orçamento é um preset que permite ao usuário solicitar um orçamento.",
          nodes: [
            {
              id: 1,
              x: -651,
              y: -79,
              chatbot_fluxo_id: 1038,
              chatbot_fluxo_estagio_id: 3803,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "0-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Envie a foto da requisição médica ou informe-nos o que deseja.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "1 - Enviar Mensagem",
              label: "1 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 2,
              x: -656,
              y: 30,
              chatbot_fluxo_id: 1038,
              chatbot_fluxo_estagio_id: 3802,
              chatbot_estagio_id: 18,
              ces_estagio: "AGUARDAR_RESPOSTA",
              ces_nome: "1-Aguardar Resposta Genérica",
              ces_descricao: "Aguarda o cliente enviar um arquivo ou mensagem",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome do arquivo/mensagem",
                    value: "respostaServicoCliente",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value:
                      "Resposta do cliente para requisição médica ou serviço desejado.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "2 - Aguardar Resposta Genérica",
              label: "2 - Aguardar Resposta Genérica",
              color: "#C7F2A4"
            },
            {
              chatbot_estagio_id: 5,
              ces_estagio: "REDIRECIONAR_LIVECHAT",
              ces_nome: "Redirecionar para o Atende",
              ces_descricao: "Redirecionar para o Atende e finaliza o processo",
              ces_metadata: {
                cor: "#B6E388",
                qtd_max_filho: 0,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Time que receberá o pedido do atendimento",
                    value: null,
                    bindText: "cfe_time",
                    type: "select",
                    required: false
                  },
                  {
                    label: "Mensagem que será enviada ao atendente",
                    value: null,
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: true
                  },
                  {
                    label: "Parâmetros enviadas ao atendente",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "multiSelect",
                    required: false
                  }
                ]
              },
              ces_visivel: true,
              cor: "#B6E388",
              x: -660,
              y: 143,
              type: "4 - Redirecionar para o Atende",
              label: "4 - Redirecionar para o Atende",
              id: 4
            }
          ],
          links: [
            { id: 1, from: 1, to: 2 },
            { id: 2, from: 2, to: 4 }
          ]
        },
        {
          id: 3,
          nome: "Dúvidas",
          descricao:
            "O preset de dúvidas é um preset que permite ao usuário tirar dúvidas sobre o serviço.",
          nodes: [
            {
              id: 1,
              x: -797,
              y: -93,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26000,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "0-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Qual seria a sua dúvida?\n1 – Endereço e telefone das nossas unidades\n2 – Horário de Funcionamento\n3 – Planos e convênios atendidos\n4 – Outra informação",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "1 - Enviar Mensagem",
              label: "1 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 2,
              x: -814,
              y: 104,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26001,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "1-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Segunda a sexta das 08:00 às 19:00\nSábado das 08:00 às 14:00",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "2 - Enviar Mensagem",
              label: "2 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 3,
              x: -694,
              y: 106,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26002,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "2-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value: "Qual informação que você deseja?",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "3 - Enviar Mensagem",
              label: "3 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 4,
              x: -696,
              y: 316,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26003,
              chatbot_estagio_id: 5,
              ces_estagio: "REDIRECIONAR_LIVECHAT",
              ces_nome: "3-Redirecionar para o Atende",
              ces_descricao: "Redirecionar para o Atende e finaliza o processo",
              ces_visivel: true,
              ces_metadata: {
                cor: "#B6E388",
                qtd_max_filho: 0,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Time que receberá o pedido do atendimento",
                    value: null,
                    bindText: "cfe_time",
                    type: "select",
                    required: false
                  },
                  {
                    label: "Mensagem que será enviada ao atendente",
                    value:
                      "Esse cliente foi encaminhado pelo fluxo de Dúvidas.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Parâmetros enviados ao atendente",
                    value: [
                      { text: "duvidas", code: "duvidas" },
                      { text: "informacaoCliente", code: "informacaoCliente" },
                      { text: "repetirDuvida", code: "repetirDuvida" }
                    ],
                    bindText: "cfe_atributo",
                    type: "multiSelect",
                    required: false
                  }
                ]
              },
              type: "4 - Redirecionar para o Atende",
              label: "4 - Redirecionar para o Atende",
              color: "#B6E388"
            },
            {
              id: 5,
              x: -700,
              y: 210,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26004,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "4-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "informacaoCliente",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "O cliente gostaria de obter outra informação",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "5 - Aguardar Mensagem de Texto",
              label: "5 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 6,
              x: -653,
              y: -92,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26005,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "5-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "duvidas",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "duvidas",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "6 - Aguardar Mensagem de Texto",
              label: "6 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 7,
              x: -645.5,
              y: 7,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26006,
              chatbot_estagio_id: 10,
              ces_estagio: "CONDICAO",
              ces_nome: "6-Tomar Decisão",
              ces_descricao: "Tomar Decisão",
              ces_visivel: true,
              ces_metadata: {
                cor: "#FFF6BF",
                qtd_max_filho: 10,
                qtd_min_filho: 2,
                proximo_estagio_id: null,
                filho_anterior: true,
                campos: [
                  [
                    {
                      label: "Estágio que a condição leva",
                      value: 2,
                      bindText: "para_chatbot_fluxo_estagio_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Condição que leva ao estágio",
                      value: "{duvidas}===2",
                      bindText: "cec_condicao",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Ordem de verificação dessa condição",
                      value: 2,
                      bindText: "cec_ordem",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Parâmetro",
                      value: null,
                      bindText: "cec_parametro",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Operador",
                      value: null,
                      bindText: "cec_operador",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Valor",
                      value: null,
                      bindText: "cec_valor",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Avançado",
                      onLabel: "Modo Padrão",
                      offLabel: "Modo Avançado",
                      value: true,
                      bindText: "cec_avancado",
                      type: "bool",
                      required: true
                    }
                  ],
                  [
                    {
                      label: "Estágio que a condição leva",
                      value: 3,
                      bindText: "para_chatbot_fluxo_estagio_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Condição que leva ao estágio",
                      value: "{duvidas}===4",
                      bindText: "cec_condicao",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Ordem de verificação dessa condição",
                      value: 3,
                      bindText: "cec_ordem",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Parâmetro",
                      value: null,
                      bindText: "cec_parametro",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Operador",
                      value: null,
                      bindText: "cec_operador",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Valor",
                      value: null,
                      bindText: "cec_valor",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Avançado",
                      onLabel: "Modo Padrão",
                      offLabel: "Modo Avançado",
                      value: true,
                      bindText: "cec_avancado",
                      type: "bool",
                      required: true
                    }
                  ],
                  [
                    {
                      label: "Estágio que a condição leva",
                      value: 8,
                      bindText: "para_chatbot_fluxo_estagio_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Condição que leva ao estágio",
                      value: "{duvidas}===1",
                      bindText: "cec_condicao",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Ordem de verificação dessa condição",
                      value: 8,
                      bindText: "cec_ordem",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Parâmetro",
                      value: null,
                      bindText: "cec_parametro",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Operador",
                      value: null,
                      bindText: "cec_operador",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Valor",
                      value: null,
                      bindText: "cec_valor",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Avançado",
                      onLabel: "Modo Padrão",
                      offLabel: "Modo Avançado",
                      value: true,
                      bindText: "cec_avancado",
                      type: "bool",
                      required: true
                    }
                  ],
                  [
                    {
                      label: "Estágio que a condição leva",
                      value: 9,
                      bindText: "para_chatbot_fluxo_estagio_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Condição que leva ao estágio",
                      value: "{duvidas}===3",
                      bindText: "cec_condicao",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Ordem de verificação dessa condição",
                      value: 9,
                      bindText: "cec_ordem",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Parâmetro",
                      value: null,
                      bindText: "cec_parametro",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Operador",
                      value: null,
                      bindText: "cec_operador",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Valor",
                      value: null,
                      bindText: "cec_valor",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Avançado",
                      onLabel: "Modo Padrão",
                      offLabel: "Modo Avançado",
                      value: true,
                      bindText: "cec_avancado",
                      type: "bool",
                      required: true
                    }
                  ]
                ]
              },
              type: "7 - Tomar Decisão",
              label: "7 - Tomar Decisão",
              color: "#FFF6BF"
            },
            {
              id: 8,
              x: -561,
              y: 109,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26007,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "7-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "*Clínica Wellon Santo André*\n\nAv. Pereira Barreto 1.250 – Paraíso\nSanto André\n(11) 3698-6589\n\n*Clínica WELLON Paulista*\n\nAv Paulista 5000 – Bela Vista\nSão Paulo \n(11) 3569-6596",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "8 - Enviar Mensagem",
              label: "8 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 9,
              x: -439.5,
              y: 109,
              chatbot_fluxo_id: 3579,
              chatbot_fluxo_estagio_id: 26008,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "8-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value: "PDF",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "9 - Enviar Mensagem",
              label: "9 - Enviar Mensagem",
              color: "#E1FFB1"
            }
          ],
          links: [
            { id: 1, from: 7, to: 2 },
            { id: 2, from: 7, to: 3 },
            { id: 3, from: 5, to: 4 },
            { id: 4, from: 3, to: 5 },
            { id: 5, from: 1, to: 6 },
            { id: 6, from: 6, to: 7 },
            { id: 7, from: 7, to: 8 },
            { id: 8, from: 7, to: 9 }
          ]
        },
        {
          id: 4,
          nome: "Protocolo",
          descricao:
            "O preset de protocolo é um preset que permite ao usuário solicitar um protocolo.",
          nodes: [
            {
              id: 1,
              x: -775,
              y: -33,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3843,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "0-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Para enviarmos o seu protocolo e senha precisamos confirmar alguns dados...",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "1 - Enviar Mensagem",
              label: "1 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 2,
              x: -635,
              y: -25,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3844,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "1-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value: "Qual o número do seu CPF?",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "2 - Enviar Mensagem",
              label: "2 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 3,
              x: -639,
              y: 310,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3845,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "2-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "cliente_data_nascimento",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "Data de nascimento do cliente.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "3 - Aguardar Mensagem de Texto",
              label: "3 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 4,
              x: -387,
              y: 308,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3846,
              chatbot_estagio_id: 11,
              ces_estagio: "VALIDACAO",
              ces_nome: "3-Validar Informação",
              ces_descricao: "Valida uma informação",
              ces_visivel: true,
              ces_metadata: {
                cor: "#FFF6BF",
                qtd_max_filho: 1,
                qtd_min_filho: 1,
                proximo_estagio_id: null,
                filho_anterior: true,
                campos: [
                  {
                    label: "Fluxo se a condição falhar",
                    value: 2,
                    bindText: "para_chatbot_fluxo_estagio_id",
                    type: "select",
                    required: true
                  },
                  {
                    label: "Condição para continuar",
                    value: "{atendimento_usuario} !== undefined",
                    bindText: "cec_condicao",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Corpo da Mensagem",
                    value: "Não foram encontrados exames com o CPF informado.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: true
                  }
                ]
              },
              type: "4 - Validar Informação",
              label: "4 - Validar Informação",
              color: "#FFF6BF"
            },
            {
              id: 5,
              x: -906,
              y: -33,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3842,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "4-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Em fase de teste. Digite o número de telefone do cliente desejado. Ex de entrada: número 66996962186 e CPF: 05958442198",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "5 - Enviar Mensagem",
              label: "5 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 6,
              x: -638,
              y: 195,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3847,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "5-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Qual a sua data de nascimento?\nFormato (DD/MM/AAAA)",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "6 - Enviar Mensagem",
              label: "6 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 7,
              x: -510,
              y: 310,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3848,
              chatbot_estagio_id: 3,
              ces_estagio: "CONSULTAR_DATANET",
              ces_nome: "6-Consultar informação do BD",
              ces_descricao: "Executar Consulta",
              ces_visivel: true,
              ces_metadata: {
                cor: "#B6E388",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  [
                    {
                      label: "Nome da Variável",
                      value: "exames",
                      bindText: "cfe_variavel",
                      type: "text",
                      required: true
                    },
                    {
                      label: "Descrição utilizada ao redirecionar",
                      value: "Consulta de Resultado de Exames",
                      bindText: "cfe_atributo",
                      type: "textArea",
                      required: false
                    },
                    {
                      label: "Agente",
                      value: 1362,
                      bindText: "sistema_licenciada_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Consulta que será executada",
                      value: 6,
                      bindText: "chatbot_campanha_id",
                      type: "select",
                      required: true
                    },
                    {
                      label: "Tratamento",
                      onLabel: "Tratando como Objeto",
                      offLabel: "Tratando como Array",
                      value: "true",
                      bindText: "cfe_mensagem",
                      type: "bool",
                      required: true
                    }
                  ]
                ]
              },
              type: "7 - Consultar informação do BD",
              label: "7 - Consultar informação do BD",
              color: "#B6E388"
            },
            {
              id: 8,
              x: -909.5,
              y: 79,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3849,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "7-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "destinatario",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "destinatario",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "8 - Aguardar Mensagem de Texto",
              label: "8 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 9,
              x: -635,
              y: 78,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3850,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "8-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "cpf",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "CPF do cliente.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "9 - Aguardar Mensagem de Texto",
              label: "9 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              id: 10,
              x: -261,
              y: 307,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3851,
              chatbot_estagio_id: 19,
              ces_estagio: "GERAR_LINK_CURTO",
              ces_nome: "9-Gerar Link",
              ces_descricao:
                "Gera um Link curto no modelo escolhido em um dos sistemas de terceiros",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da variável que receberá o link curto",
                    value: "link",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Modelo de Link curto",
                    value: "2",
                    bindText: "modelo_link_id",
                    type: "select",
                    required: true
                  },
                  {
                    label: "Agente",
                    value: "1362",
                    bindText: "sistema_licenciada_id",
                    type: "select",
                    required: true
                  }
                ]
              },
              type: "10 - Gerar Link",
              label: "10 - Gerar Link",
              color: "#C7F2A4"
            },
            {
              id: 11,
              x: -148.5,
              y: 310,
              chatbot_fluxo_id: 1081,
              chatbot_fluxo_estagio_id: 3852,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "10-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value: "Você pode acessar seus exames pelo link {link}",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "11 - Enviar Mensagem",
              label: "11 - Enviar Mensagem",
              color: "#E1FFB1"
            }
          ],
          links: [
            { id: 1, from: 8, to: 1 },
            { id: 2, from: 1, to: 2 },
            { id: 3, from: 6, to: 3 },
            { id: 4, from: 7, to: 4 },
            { id: 5, from: 9, to: 6 },
            { id: 6, from: 3, to: 7 },
            { id: 7, from: 5, to: 8 },
            { id: 8, from: 2, to: 9 },
            { id: 10, from: 10, to: 11 }
          ]
        },
        {
          id: 6,
          nome: "Reclamação",
          descricao:
            "O preset de reclamação é um preset que permite ao usuário reclamar de algum serviço.",
          nodes: [
            {
              id: 1,
              x: -755,
              y: -78,
              chatbot_fluxo_id: 774,
              chatbot_fluxo_estagio_id: 1848,
              chatbot_estagio_id: 1,
              ces_estagio: "ENVIAR_MENSAGEM",
              ces_nome: "0-Enviar Mensagem",
              ces_descricao: "Envia mensagem para o cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#E1FFB1",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Corpo da Mensagem",
                    value:
                      "Por favor, fale para a gente sua reclamação ou sugestão.",
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Arquivo a ser enviado",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "file",
                    required: false
                  }
                ]
              },
              type: "1 - Enviar Mensagem",
              label: "1 - Enviar Mensagem",
              color: "#E1FFB1"
            },
            {
              id: 3,
              x: -755,
              y: 29,
              chatbot_fluxo_id: 774,
              chatbot_fluxo_estagio_id: 1850,
              chatbot_estagio_id: 2,
              ces_estagio: "LER_MENSAGEM",
              ces_nome: "2-Aguardar Mensagem de Texto",
              ces_descricao: "Aguardar Resposta do Cliente",
              ces_visivel: true,
              ces_metadata: {
                cor: "#C7F2A4",
                qtd_max_filho: 1,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Nome da Variável",
                    value: "reclamacaoCliente",
                    bindText: "cfe_variavel",
                    type: "text",
                    required: true
                  },
                  {
                    label: "Descrição utilizada ao redirecionar",
                    value: "Reclamação do cliente.",
                    bindText: "cfe_atributo",
                    type: "textArea",
                    required: false
                  },
                  {
                    label: "Formatação da Entrada",
                    value: null,
                    bindText: "chatbot_formatacao_entrada_id",
                    type: "select",
                    required: false
                  }
                ]
              },
              type: "3 - Aguardar Mensagem de Texto",
              label: "3 - Aguardar Mensagem de Texto",
              color: "#C7F2A4"
            },
            {
              chatbot_estagio_id: 5,
              ces_estagio: "REDIRECIONAR_LIVECHAT",
              ces_nome: "Redirecionar para o Atende",
              ces_descricao: "Redirecionar para o Atende e finaliza o processo",
              ces_metadata: {
                cor: "#B6E388",
                qtd_max_filho: 0,
                qtd_min_filho: 0,
                filho_anterior: false,
                proximo_estagio_id: null,
                campos: [
                  {
                    label: "Time que receberá o pedido do atendimento",
                    value: null,
                    bindText: "cfe_time",
                    type: "select",
                    required: false
                  },
                  {
                    label: "Mensagem que será enviada ao atendente",
                    value: null,
                    bindText: "cfe_mensagem",
                    type: "textArea",
                    required: true
                  },
                  {
                    label: "Parâmetros enviadas ao atendente",
                    value: null,
                    bindText: "cfe_atributo",
                    type: "multiSelect",
                    required: false
                  }
                ]
              },
              ces_visivel: true,
              cor: "#B6E388",
              x: -754.5,
              y: 141,
              type: "4 - Redirecionar para o Atende",
              label: "4 - Redirecionar para o Atende",
              id: 4
            }
          ],
          links: [
            { id: 2, from: 1, to: 3 },
            { id: 3, from: 3, to: 4 }
          ]
        }
      ]
    };
  },
  methods: {
    confirmarPreset() {
      Swal.fire({
        title: `Tem certeza que deseja inserir o preset de '${this.presetAtivo.nome}'?`,
        text: "Você não poderá desfazer essa ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, confirmar!"
      }).then((result) => {
        if (result.value) {
          this.$emit(
            "confirmarPreset",
            this.presetAtivo.nodes,
            this.presetAtivo.links
          );
          this.$modal.hide("modal-presets");
        }
      });
    },
    selecionarPresetAgendamento() {
      this.itemSelecionado = 1;
    },
    selecionarPresetOrcamento() {
      this.itemSelecionado = 2;
    },
    selecionarPresetDuvidas() {
      this.itemSelecionado = 3;
    },
    selecionarPresetProtocolo() {
      this.itemSelecionado = 4;
    },
    selecionarPresetAdmin() {
      this.itemSelecionado = 5;
    },
    selecionarPresetReclamacao() {
      this.itemSelecionado = 6;
    }
  },
  mounted() {
    // this.pegarMenusChatbot();
  },
  computed: {
    modalSize() {
      if (window.screen.width > 900) return "900";
      return "400";
    },
    presetAtivo() {
      const ativo = this.presetsOpcoes.find(
        (p) => p.id == this.itemSelecionado
      );
      return ativo ? ativo : {};
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-presets {
  padding: 24px !important;
  .flexer-container {
    &.presets {
      gap: 1rem;
      /* justify-content: center; */
    }
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    /* align-items: center; */
    min-height: 100% !important;
    b-button {
      &.active {
        background-color: #28a745 !important;
        border-color: #28a745 !important;
      }
    }
  }

  .buttons-confirmar-presets {
    display: flex;
    gap: 1rem;
  }

  .flexer-col {
    /* flex: 4; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100% !important;

    /* only first child
         */
    > .form-group:first-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
